import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PUBLIC_URL } from '../../constants';

import NotFoundPage from '../../components/NotFoundPage';
import ProfilePage from '../ProfilePage';
import LoginPage from '../LoginPage';
import Logout from '../Logout';
import Version from '../Version';

import IncomingRequests from '../../components/IncomingRequests';
import ManageRequestsPage from '../../components/ManageRequest';
import ClosedRequestsPage from '../ClosedRequestsPage';

import SearchCustomersPage from '../SearchCustomersPage';
import EditCustomerPage from '../../components/UserManagement/EditUserPage';

import SearchOrders from '../OrderManagement/SearchOrders';
import ChatContainer from '../OrderManagement/ChatContainer';

import DevPage from '../Dev';
import CompaniesPage from '../CompaniesPage';
import EditCompanyPage from '../../components/CompaniesManagement/EditCompanyPage';
import NetworkOperationsCenter from '../../components/NetworkOperationsCenter';
import EplaneSettings from '../GeneralSettings';
import PaymentsTasksPage from '../PaymentsTasks';
import MasterPartsPage from '../masterParts';

const appRouting = (
  <Switch>
    <Redirect exact from={PUBLIC_URL || '/'} to="/companies" />
    <Redirect exact from="/rfq" to="/orders" />

    <Route exact from="/masterParts"><MasterPartsPage /></Route>

    {/* Companies */}
    <Route exact path="/companies"><CompaniesPage /></Route>
    <Route path="/companies/:userId"><EditCompanyPage /></Route>

    {/* Incoming Requests */}
    <Route exact path="/incoming"><IncomingRequests /></Route>
    <Route path="/incoming/closed"><ClosedRequestsPage /></Route>
    <Route path="/incoming/:id"><ManageRequestsPage /></Route>

    {/* Customer Management */}
    <Route exact path="/userManagement"><SearchCustomersPage /></Route>
    <Route path="/userManagement/:userId/:customerId"><EditCustomerPage /></Route>

    {/* Order Management */}
    <Route exact path="/orders"><SearchOrders /></Route>
    <Route path="/orders/:orderId"><ChatContainer /></Route>

    {/* Dev */}
    <Route path="/dev"><DevPage /></Route>

    {/* Payments Tasks */}
    <Route exact path="/paymentsTasks"><PaymentsTasksPage /></Route>
    <Route path="/paymentsTasks/:taskId"><PaymentsTasksPage /></Route>

    {/* Network Operations Center */}
    <Route exact path="/networkOperations"><NetworkOperationsCenter /></Route>
    <Route path="/networkOperations/:taskId"><NetworkOperationsCenter /></Route>

    {/* General Settings Page */}
    <Route path="/eplaneSettings"><EplaneSettings /></Route>

    <Route path="/login"><LoginPage /></Route>
    <Route path="/profile"><ProfilePage /></Route>
    <Route path="/logout"><Logout /></Route>
    <Route path="/version.txt"><Version /></Route>
    <Route><NotFoundPage /></Route>
  </Switch>
);

export default appRouting;
