/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import axios from 'axios';
import { Button, Table } from 'react-bootstrap';
import FormTitleSection from '../../components/FormComponents/TitleSection';

const MasterParts = () => {
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const UPLOAD_URL = '/rest/V2/eplane/backoffice/masterparts';

  const MasterpartsStatusEnum = {
    INITIATED: 'initiated',
    PROCESSING: 'processing',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    NOT_UPLOADED: 'not_uploaded',
  };

  const [isChecked, setIsChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isFileSelectEnabled, setIsFileSelectEnabled] = useState(false);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await axios.get('/rest/V2/eplane/backoffice/masterparts');
      setTableData(data.payload || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch
  }, []);

  // Memoize getStatusColor to avoid unnecessary recalculations
  const getStatusColor = useCallback((status) => {
    switch (status) {
      case MasterpartsStatusEnum.INITIATED:
        return 'gray';
      case MasterpartsStatusEnum.PROCESSING:
        return 'yellow';
      case MasterpartsStatusEnum.SUCCEEDED:
        return 'green';
      case MasterpartsStatusEnum.FAILED:
        return 'red';
      case MasterpartsStatusEnum.NOT_UPLOADED:
        return 'red';
      default:
        return 'black';
    }
  }, [MasterpartsStatusEnum]);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month (0-indexed)
  //   const year = date.getFullYear(); // Full year
  //   const hours = String(date.getUTCHours()).padStart(2, '0'); // UTC hour
  //   const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // UTC minutes
  //   const seconds = String(date.getUTCSeconds()).padStart(2, '0'); // UTC seconds

  //   return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} UTC`;
  // };

  // Memoized columns and CellWithTitle component

  const CellWithTitle = ({
    value, accessor, row, onRetry, showRetry,
  }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const handleButtonClick = async () => {
      setIsLoading(true);
      try {
        // First API call to download the file
        const fileResponse = await axios.get(`/rest/V2/eplane/backoffice/masterparts/${row.masterpartsVersion}/download`, {
          responseType: 'blob',
        });

        // Handle the file download
        downloadFile(fileResponse.data, 'file.json');

        // Optional: If another API call is needed for a URL in the payload
        const secondResponse = await axios.get(`/rest/V2/eplane/backoffice/masterparts/${row.masterpartsVersion}/download`);
        const { url } = secondResponse.data.payload;

        if (url) {
          const urlFileResponse = await axios.get(url, {
            responseType: 'blob',
          });
          downloadFile(urlFileResponse.data, 'file.json');
        } else {
          console.error('URL is empty in the response payload');
        }
      } catch (error) {
        console.error('Error making API call:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Helper function to download a file
    const downloadFile = (fileData, filename) => {
      const blob = new Blob([fileData]);
      const downloadUrl = URL.createObjectURL(blob);

      // Create a hidden anchor tag to trigger download
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Release the object URL
      URL.revokeObjectURL(downloadUrl);
    };

    const isStatusWithButton = accessor === 'url' && ['succeeded', 'failed'].includes(row.status);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isStatusWithButton ? (
          <button
            type="button"
            onClick={handleButtonClick}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #ccc',
              padding: '5px 10px',
              cursor: 'pointer',
            }}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Download'}
          </button>
        ) : (
          <span>{value}</span>
        )}
        {accessor === 'status' && value === MasterpartsStatusEnum.FAILED && showRetry && (
          <Button
            variant="danger"
            size="sm"
            style={{ marginLeft: '8px' }}
            onClick={() => onRetry(row.id)}
          >
            Retry
          </Button>
        )}
      </div>
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setIsUploadEnabled(true);
  };

  const uploadChunk = async (chunk, chunkNumber, totalChunks, uploadedBytes) => {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('chunkNumber', chunkNumber);
    formData.append('totalChunks', totalChunks);
    formData.append('fileName', file.name);

    try {
      await axios.post(UPLOAD_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: ({ loaded }) => {
          const chunkProgress = loaded / chunk.size;
          const totalProgress = ((uploadedBytes + chunkProgress * chunk.size) / file.size) * 100;
          setUploadProgress(Math.min(totalProgress, 100));
        },
      });
    } catch (error) {
      console.error('Error uploading chunk:', error);
      throw error;
    }
  };

  const uploadFileByChunks = async () => {
    if (!file) return console.warn('Please select a file first!');
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedBytes = 0;
    setUploadProgress(0);

    try {
      for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
        const start = chunkNumber * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        await uploadChunk(chunk, chunkNumber, totalChunks, uploadedBytes);
        uploadedBytes += chunk.size; // Update the uploaded bytes here
      }
      // Reset progress
      setUploadProgress(0);
      // Reset file
      setFile(null);

      // Recall fetchData to refresh table data
      fetchData();
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };

  // Memoized columns and CellWithTitle component
  const columns = useMemo(() => {
    const CellWithTitle = ({
      value, accessor, row, onRetry, showRetry,
    }) => {
      const color = accessor === 'status' ? getStatusColor(value) : undefined;

      return (
        <div style={{ display: 'flex', alignItems: 'center', color }}>
          <span>{value}</span>
          {/* Show Retry button only for the last failed record */}
          {accessor === 'status' && value === MasterpartsStatusEnum.FAILED && showRetry && (
            <Button
              variant="danger"
              size="sm"
              style={{ marginLeft: '8px' }}
              onClick={() => onRetry(row.id)}
            >
              Retry
            </Button>
          )}
        </div>
      );
    };

    return [
      {
        accessor: 'masterpartsVersion',
        header: 'File Version',
        style: { width: '120px' },
      },
      {
        accessor: 'updated_at',
        header: 'Uploaded',
        style: { width: '180px' },
      },
      {
        accessor: 'status',
        header: 'Status',
        style: { width: '220px' },
        Cell: CellWithTitle,
      },
      {
        accessor: 'url',
        header: 'File Link',
        Cell: CellWithTitle,
      },
    ];
  }, [MasterpartsStatusEnum.FAILED, getStatusColor]);

  useEffect(() => {
    const fetchCheckboxStatus = async () => {
      try {
        const { data } = await axios('/rest/V2/eplane/backoffice/masterparts/state');
        const checker = data.payload.state === 'active';
        setIsChecked(checker);
        setIsFileSelectEnabled(checker);
      } catch (error) {
        console.error('Failed to fetch checkbox status:', error);
      }
    };

    fetchCheckboxStatus();
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = async (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    setIsFileSelectEnabled(checked);

    try {
      const checker = checked === true ? 'active' : 'disabled';
      await axios.post('/rest/V2/eplane/backoffice/masterparts/state', { state: checker });
    } catch (error) {
      console.error('Failed to update checkbox status:', error);
    }
  };

  // Last failed
  // const lastFailedIndex = useMemo(() => tableData
  //   .map((row) => row.status)
  //   .lastIndexOf(MasterpartsStatusEnum.FAILED), [MasterpartsStatusEnum.FAILED, tableData]);

  // Retry function to handle the API call
  // const handleRetry = async (rowId) => {
  //   try {
  //     // setLoading(true);
  //     const response = await axios.post('/rest/V2/eplane/backoffice/masterparts/retry', { id: rowId });

  //     // Assuming the response contains updated row data
  //     const updatedRow = response.data;

  //     // Update the table data with the new status for the specific row
  //     setTableData((prevData) => prevData.map((row) => (row.id === rowId ? { ...row, status: updatedRow.status } : row)));
  //   } catch (error) {
  //     console.error('Retry failed:', error);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };

  //
  return (
    <div className="mx-auto w-75 d-flex flex-column">
      <div className="mt-5">
        <FormTitleSection title="Master Parts" showEditButton={false} />
        <div className="d-flex w-100 justify-content-between mt-3 mb-3">
          <label htmlFor="searchMasterParts" className="d-flex align-items-center">
            Use Master Parts document
            <input
              type="checkbox"
              id="searchMasterParts"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="me-2 ml-2"
            />
          </label>
          <div>
            {uploadProgress > 0 && (
            <div>
              Upload Progress:
              <span>
                {uploadProgress.toFixed(2)}
                %
              </span>
            </div>
            )}
            <input
              type="file"
              accept="application/JSON"
              disabled={!isFileSelectEnabled}
              onChange={handleFileChange}
            />
            <button
              type="button"
              disabled={!isUploadEnabled}
              className="btn btn-success btn-md"
              onClick={uploadFileByChunks}
            >
              Upload File
            </button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              {columns.map((col, idx) => (
                <th key={idx} style={col.style}>
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                style={{
                  backgroundColor: row.isActive ? 'lightgreen' : 'transparent',
                }}
              >
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    <CellWithTitle value={row[col.accessor]} row={row} accessor={col.accessor} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MasterParts;
