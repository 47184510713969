export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const CHECK_LOGGED_IN_START = 'CHECK_LOGGED_IN_START';
export const CHECK_LOGGED_IN_VERIFIED = 'CHECK_LOGGED_IN_VERIFIED';
export const CHECK_LOGGED_IN_FAILED = 'CHECK_LOGGED_IN_FAILED';

export const ROUTING = 'ROUTING';

export const COMPANY_LIST_FETCH = 'COMPANY_LIST_FETCH';
export const COMPANY_LIST_IS_FETCHED = 'COMPANY_LIST_IS_FETCHED';
export const COMPANY_LIST_ERROR_SET = 'COMPANY_LIST_ERROR_SET';
export const COMPANY_LIST_ERROR_REMOVE = 'COMPANY_LIST_ERROR_REMOVE';

/* ******************** server constants ******************************* */

export const SERVER_CONSTANTS_GET = 'SERVER_CONSTANTS_GET';
export const SERVER_CONSTANTS_GET_SUCCESS = 'SERVER_CONSTANTS_GET_SUCCESS';
export const SERVER_CONSTANTS_GET_FAIL = 'SERVER_CONSTANTS_GET_FAIL';
export const SERVER_CONSTANTS_ERROR_REMOVE = 'SERVER_CONSTANTS_ERROR_REMOVE';

/* ********************** authorization ********************************* */

export const AUTHORIZATION_FAIL = 'AUTHORIZATION_FAIL';

/* ******************** incoming requests ******************************* */

// export const REQUESTS_GET = 'REQUESTS_GET';
// export const REQUESTS_GET_SUCCESS = 'REQUESTS_GET_SUCCESS';
// export const REQUESTS_GET_FAIL = 'REQUESTS_GET_FAIL';
// export const REQUESTS_SEARCH = 'REQUESTS_SEARCH';
// export const CLOSED_REQUEST_TABLE_SETTINGS_SET = 'CLOSED_REQUEST_TABLE_SETTINGS_SET';
export const INCOMING_REQUESTS_FETCH = 'INCOMING_REQUEST_FETCH';
export const INCOMING_REQUESTS_ARE_FETCHED = 'INCOMING_REQUEST_ARE_FETCH';
export const INCOMING_REQUESTS_SEARCHING_START = 'INCOMING_REQUESTS_SEARCHING_START';
export const INCOMING_REQUESTS_SEARCHING_FINISHED = 'INCOMING_REQUESTS_SEARCHING_FINISHED';
export const INCOMING_REQUEST_ERROR_OCCURRED = 'INCOMING_REQUEST_ERROR_OCCURRED';

export const CLOSED_REQUESTS_FETCH = 'CLOSED_REQUESTS_FETCH';
export const CLOSED_REQUESTS_ARE_FETCHED = 'CLOSED_REQUESTS_ARE_FETCHED';
export const CLOSED_REQUESTS_SEARCHING_START = 'CLOSED_REQUESTS_SEARCHING_START';
export const CLOSED_REQUESTS_SEARCHING_FINISHED = 'CLOSED_REQUESTS_SEARCHING_FINISHED';
export const CLOSED_REQUEST_ERROR_OCCURRED = 'CLOSED_REQUEST_ERROR_OCCURRED';
export const CLOSED_REQUEST_TABLE_OPTIONS_SET = 'CLOSED_REQUEST_TABLE_OPTIONS_SET';
export const CLOSED_REQUEST_RESET_DATA = 'CLOSED_REQUEST_RESET_DATA';

export const REQUEST_DATA_FETCH = 'REQUEST_DATA_FETCH';
export const REQUEST_DATA_ARE_FETCHED = 'REQUEST_DATA_ARE_FETCHED';
export const REQUEST_DATA_PROCESS_DECISION = 'REQUEST_DATA_PROCESS_DECISION';
export const REQUEST_DATA_DECISION_IS_PROCESSED = 'REQUEST_DATA_DECISION_IS_PROCESSED';
export const REQUEST_DATA_ERROR_OCCURRED = 'INCOMING_REQUEST_ERROR_OCCURRED';

export const REQUEST_DATA_CUSTOMERS_ARE_FETCHED = 'REQUEST_DATA_CUSTOMERS_ARE_FETCHED';

/* ******************** Order Management ******************************* */

export const COMPANIES_GET = 'COMPANIES_GET';
export const COMPANIES_GET_SUCCESS = 'COMPANIES_GET_SUCCESS';

export const REQUEST_FOR_DATA_FAIL = 'REQUESTS_FOR_DATA_FAIL';

// export const ORDERS_SEARCH_SUBMIT = 'ORDERS_SEARCH_SUBMIT';
// export const ORDERS_SEARCH_PAGINATION_SELECT = 'ORDERS_SEARCH_PAGINATION_SELECT';

export const ORDERS_SEARCH = 'ORDERS_SEARCH';
export const ORDERS_SEARCH_SUCCESS = 'ORDERS_SEARCH_SUCCESS';
export const ORDERS_SEARCH_SHOW_ALL_SUCCESS = 'ORDERS_SEARCH_SHOW_ALL_SUCCESS';

// export const ORDERS_SHOW_ALL = 'ORDERS_SHOW_ALL';

export const CHAT_DATA_FETCH = 'CHAT_DATA_FETCH';
export const CHAT_DATA_IS_FETCHED = 'CHAT_DATA_IS_FETCHED';
export const CHAT_DATA_CLEAR = 'CHAT_DATA_CLEAR';
export const CHAT_ERROR_IS_OCCURRED = 'CHAT_ERROR_IS_OCCURRED';

/* ******************** User Management ******************************* */

export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS';
export const CUSTOMER_GET_FAIL = 'CUSTOMER_GET_FAIL';
export const CUSTOMER_ERROR_REMOVE = 'CUSTOMER_ERROR_REMOVE';
export const CUSTOMER_SAVE = 'CUSTOMER_SAVE';
export const CUSTOMER_SAVE_SUCCESS = 'CUSTOMER_SAVE_SUCCESS';
export const CUSTOMER_SAVE_FAIL = 'CUSTOMER_SAVE_FAIL';
export const CUSTOMER_PASSWORD_SAVE = 'CUSTOMER_PASSWORD_SAVE';
export const CUSTOMER_PASSWORD_SAVE_SUCCESS = 'CUSTOMER_PASSWORD_SAVE_SUCCESS';
export const CUSTOMER_PASSWORD_SAVE_FAIL = 'CUSTOMER_PASSWORD_SAVE_FAIL';
export const CUSTOMER_PASSWORD_RESET = 'CUSTOMER_PASSWORD_RESET';
export const CUSTOMER_PASSWORD_RESET_SUCCESS = 'CUSTOMER_PASSWORD_RESET_SUCCESS';
export const CUSTOMER_PASSWORD_RESET_FAIL = 'CUSTOMER_PASSWORD_RESET_FAIL';
export const CUSTOMER_STATE_CLEAR = 'CUSTOMER_STATE_CLEAR';
export const CUSTOMER_EMAIL_RESEND = 'CUSTOMER_EMAIL_RESEND';
export const CUSTOMER_EMAIL_RESEND_SUCCESS = 'CUSTOMER_EMAIL_RESEND_SUCCESS';
export const CUSTOMER_EMAIL_RESEND_FAIL = 'CUSTOMER_EMAIL_RESEND_FAIL';
export const CUSTOMER_ACCOUNT_CONFIRMATION = 'CUSTOMER_ACCOUNT_CONFIRMATION';
export const CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS = 'CUSTOMER_ACCOUNT_CONFIRMATION_SUCCESS';
export const CUSTOMER_ACCOUNT_CONFIRMATION_FAIL = 'CUSTOMER_ACCOUNT_CONFIRMATION_FAIL';
export const CUSTOMER_ERROR_CLEAR = 'CUSTOMER_ERROR_CLEAR';

export const CUSTOMERS_LIST_GET = 'CUSTOMERS_LIST_GET';
export const CUSTOMERS_LIST_GET_SUCCESS = 'CUSTOMERS_LIST_GET_SUCCESS';
export const CUSTOMERS_LIST_FAIL = 'CUSTOMERS_LIST_FAIL';

export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAIL = 'USER_GET_FAIL';
export const USER_SAVE = 'USER_SAVE';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_FAIL = 'USER_SAVE_FAIL';
export const USER_LOGO_UPLOAD = 'USER_LOGO_UPLOAD';
export const USER_LOGO_UPLOAD_SUCCESS = 'USER_LOGO_UPLOAD_SUCCESS';
export const USER_LOGO_UPLOADED_REMOVE = 'USER_LOGO_UPLOADED_REMOVE';
export const USER_LOGO_UPLOAD_FAIL = 'USER_LOGO_UPLOAD_FAIL';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_CHECKING_CUSTOMER_START = 'USER_CHECKING_CUSTOMER_START';
export const USER_CHECKING_CUSTOMER_SUCCESS = 'USER_CHECKING_CUSTOMER_SUCCESS';
export const USER_ERROR_SET = 'USER_ERROR_SET';
export const USER_ERROR_REMOVE = 'USER_ERROR_REMOVE';

export const USER_SETTINGS_FETCH = 'USER_SETTINGS_FETCH';
export const USER_SETTINGS_IS_FETCHED = 'USER_SETTINGS_IS_FETCHED';
export const USER_SETTINGS_FATAL_ERROR_OCCURRED = 'USER_SETTINGS_FATAL_ERROR_OCCURRED';
export const USER_SETTINGS_SAVE = 'USER_SETTINGS_SAVE';
export const USER_SETTINGS_IS_SAVED = 'USER_SETTINGS_IS_SAVED';
export const USER_SETTINGS_SAVE_ERROR = 'USER_SETTINGS_SAVE_ERROR';
export const USER_SETTINGS_ERROR_REMOVE = 'USER_SETTINGS_ERROR_REMOVE';
export const USER_SETTINGS_EDIT_MODE_TOGGLE = 'USER_SETTINGS_EDIT_MODE_TOGGLE';

// TODO remove later
export const USER_TYPE_PRIVATE = 1;
export const USER_TYPE_COMPANY = 2;

// TODO remove later
export const SHIPPING_SITE = 1;
export const PICKUP_SITE = 2;
export const BILLING_SITE = 3;

export const USER_SITES_GET = 'USER_SITES_GET';
export const USER_SITES_GET_SUCCESS = 'USER_SITES_GET_SUCCESS';
export const USER_SITES_GET_FAIL = 'USER_SITES_GET_FAIL';
export const USER_SITE_SAVE = 'USER_SITE_SAVE';
export const USER_SITE_SAVE_SUCCESS = 'USER_SITE_SAVE_SUCCESS';
export const USER_SITE_SAVE_FAIL = 'USER_SITE_SAVE_FAIL';
export const USER_SITE_DELETING_START = 'USER_SITE_DELETING_START';
export const USER_SITE_DELETE_SUCCESS = 'USER_SITE_DELETE_SUCCESS';
export const USER_SITE_DELETE_FAIL = 'USER_SITE_DELETE_FAIL';
export const USER_SITES_ERRORS_REMOVE = 'USER_SITES_ERRORS_REMOVE';
export const USER_SITE_MODAL_CREATE_OPEN = 'USER_SITE_MODAL_CREATE_OPEN';
export const USER_SITE_MODAL_EDIT_OPEN = 'USER_SITE_MODAL_EDIT_OPEN';
export const USER_SITE_SELECT = 'USER_SITE_SELECT';
export const USER_SITE_MODAL_CLOSE = 'USER_SITE_MODAL_CLOSE';
export const USER_SITE_MODAL_REMOVE_OPEN = 'USER_SITE_MODAL_REMOVE_OPEN';
export const USER_SITE_MODAL_REMOVE_CLOSE = 'USER_SITE_MODAL_REMOVE_CLOSE';

export const COMPANY_CUSTOMER_GET_LIST = 'COMPANY_CUSTOMER_GET_LIST';
export const COMPANY_CUSTOMER_GET_LIST_SUCCESS = 'COMPANY_CUSTOMER_GET_LIST_SUCCESS';
export const COMPANY_CUSTOMER_GET_LIST_FAIL = 'COMPANY_CUSTOMER_GET_LIST_FAIL';
export const COMPANY_CUSTOMER_INVITE = 'COMPANY_CUSTOMER_INVITE';
export const COMPANY_CUSTOMER_INVITE_SUCCESS = 'COMPANY_CUSTOMER_INVITE_SUCCESS';
export const COMPANY_CUSTOMER_INVITE_FAIL = 'COMPANY_CUSTOMER_INVITE_FAIL';
export const COMPANY_CUSTOMER_REINVITE = 'COMPANY_CUSTOMER_REINVITE';
export const COMPANY_CUSTOMER_REINVITE_SUCCESS = 'COMPANY_CUSTOMER_REINVITE_SUCCESS';
export const COMPANY_CUSTOMER_REINVITE_FAIL = 'COMPANY_CUSTOMER_REINVITE_FAIL';
export const COMPANY_CUSTOMER_UPDATE = 'COMPANY_CUSTOMER_UPDATE';
export const COMPANY_CUSTOMER_UPDATE_SUCCESS = 'COMPANY_CUSTOMER_UPDATE_SUCCESS';
export const COMPANY_CUSTOMER_UPDATE_FAIL = 'COMPANY_CUSTOMER_UPDATE_FAIL';
export const COMPANY_CUSTOMER_DEACTIVATE = 'COMPANY_CUSTOMER_DEACTIVATE';
export const COMPANY_CUSTOMER_DEACTIVATE_SUCCESS = 'COMPANY_CUSTOMER_DEACTIVATE_SUCCESS';
export const COMPANY_CUSTOMER_DEACTIVATE_FAIL = 'COMPANY_CUSTOMER_DEACTIVATE_FAIL';
export const COMPANY_CUSTOMER_REACTIVATE = 'COMPANY_CUSTOMER_DEACTIVATE';
export const COMPANY_CUSTOMER_REACTIVATE_SUCCESS = 'COMPANY_CUSTOMER_DEACTIVATE_SUCCESS';
export const COMPANY_CUSTOMER_REACTIVATE_FAIL = 'COMPANY_CUSTOMER_DEACTIVATE_FAIL';
export const COMPANY_CUSTOMER_DISMISS = 'COMPANY_CUSTOMER_DISMISS';
export const COMPANY_CUSTOMER_DISMISS_SUCCESS = 'COMPANY_CUSTOMER_DISMISS_SUCCESS';
export const COMPANY_CUSTOMER_DISMISS_FAIL = 'COMPANY_CUSTOMER_DISMISS_FAIL';
export const COMPANY_CUSTOMER_REMOVE = 'COMPANY_CUSTOMER_REMOVE';
export const COMPANY_CUSTOMER_REMOVE_SUCCESS = 'COMPANY_CUSTOMER_REMOVE_SUCCESS';
export const COMPANY_CUSTOMER_REMOVE_FAIL = 'COMPANY_CUSTOMER_REMOVE_FAIL';
export const COMPANY_CUSTOMER_ERROR_REMOVE = 'COMPANY_CUSTOMER_ERROR_REMOVE';

export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL || '';
export const API_URL = global.API_URL || process.env.REACT_APP_API_URL;
export const GAPI_CLIENT_ID = global.GAPI_CLIENT_ID || process.env.REACT_APP_GAPI_CLIENT_ID;
export const GAPI_CLIENT_KEY = global.GAPI_CLIENT_KEY || process.env.REACT_APP_GAPI_CLIENT_KEY;
// documents

export const DOCUMENT_LIST_FETCH = 'DOCUMENT_LIST_FETCH';
export const DOCUMENT_LIST_IS_FETCHED = 'DOCUMENT_LIST_IS_FETCHED';
export const DOCUMENT_UPLOADING_START = 'DOCUMENT_UPLOADING_START';
export const DOCUMENT_UPLOADING_FINISH = 'DOCUMENT_UPLOADING_FINISH';
export const DOCUMENT_UPLOADING_PROGRESS_SET = 'DOCUMENT_UPLOADING_PROGRESS_SET';
export const DOCUMENT_UPLOADING_TERMINATE = 'DOCUMENT_PROGRESS_RESET';
export const DOCUMENT_REMOVE = 'DOCUMENT_REMOVE';
export const DOCUMENT_LIST_ERROR_OCCURRED = 'DOCUMENT_LIST_ERROR_OCCURRED';
export const DOCUMENT_LIST_ERROR_REMOVE = 'DOCUMENT_LIST_ERROR_REMOVE';

// Filters management

export const FILTER_LIST_GET = 'FILTER_LIST_GET';
export const FILTER_LIST_GET_SUCCESS = 'FILTER_LIST_SUCCESS';
export const FILTER_SAVE = 'FILTER_SAVE';
export const FILTER_SAVE_SUCCESS = 'FILTER_SAVE_SUCCESS';
export const FILTER_REMOVE = 'FILTER_REMOVE';
export const FILTER_REMOVE_SUCCESS = 'FILTER_REMOVE_SUCCESS';
export const FILTERS_FAIL = 'FILTERS_FAIL';
export const FILTER_NOTIFICATION = 'FILTER_NOTIFICATIONS';
export const FILTER_NOTIFICATION_SUCCESS = 'FILTER_NOTIFICATIONS_SUCCESS';
export const FILTER_NOTIFICATION_FAIL = 'FILTER_NOTIFICATIONS_FAIL';
export const FILTER_ERRORS_CLEAR = 'FILTER_ERRORS_CLEAR';
export const TIME_ZONES_GET = 'TIME_ZONES_GET';
export const TIME_ZONES_SUCCESS = 'TIME_ZONES_SUCCESS';
export const AGGREGATION_EMAILS_SAVE = 'AGGREGATION_EMAILS_SAVE';
export const AGGREGATION_EMAILS_SAVE_SUCCESS = 'AGGREGATION_EMAILS_SAVE_SUCCESS';

// Notifications management
export const USER_NOTIFICATIONS_GET = 'USER_NOTIFICATIONS_GET';
export const USER_NOTIFICATIONS_SUCCESS = 'USER_NOTIFICATIONS_SUCCESS';
export const USER_NOTIFICATIONS_FAIL = 'USER_NOTIFICATIONS_FAIL';
export const USER_NOTIFICATIONS_SAVE = 'USER_NOTIFICATIONS_SAVE';
export const USER_NOTIFICATIONS_SAVE_SUCCESS = 'USER_NOTIFICATIONS_SAVE_SUCCESS';
export const USER_NOTIFICATIONS_SAVE_FAIL = 'USER_NOTIFICATIONS_SAVE_FAIL';
export const USER_NOTIFICATIONS_ERROR_REMOVE = 'USER_NOTIFICATIONS_ERROR_REMOVE';

// Active company customers
export const COMPANY_CUSTOMERS_GET = 'COMPANY_CUSTOMERS_GET';
export const COMPANY_CUSTOMERS_GET_SUCCESS = 'COMPANY_CUSTOMERS_GET_SUCCESS';
export const COMPANY_CUSTOMERS_GET_FAIL = 'COMPANY_CUSTOMERS_GET_FAIL';
export const COMPANY_CUSTOMERS_ERROR_REMOVE = 'COMPANY_CUSTOMERS_ERROR_REMOVE';

// List of all legal entities
export const LEGAL_ENTITIES_GET = 'LEGAL_ENTITIES_GET';
export const LEGAL_ENTITIES_GET_SUCCESS = 'LEGAL_ENTITIES_GET_SUCCESS';
export const LEGAL_ENTITIES_GET_FAIL = 'LEGAL_ENTITIES_GET_FAIL';
export const LEGAL_ENTITIES_ERROR_REMOVE = 'LEGAL_ENTITIES_ERROR_REMOVE';

// Tier3 vendors categorization
export const VENDOR_CATEGORIZATION_LIST_GET = 'VENDOR_CATEGORIZATION_LIST_GET';
export const VENDOR_CATEGORIZATION_LIST_GET_SUCCESS = 'VENDOR_CATEGORIZATION_LIST_SUCCESS';
export const VENDOR_CATEGORIZATION_SAVE = 'VENDOR_CATEGORIZATION_SAVE';
export const VENDOR_CATEGORIZATION_SAVE_SUCCESS = 'VENDOR_CATEGORIZATION_SAVE_SUCCESS';
export const VENDOR_CATEGORIZATION_FAIL = 'VENDOR_CATEGORIZATION_FAIL';

export const MAILCHIMP_CONDITIONS_GET = 'MAILCHIMP_CONDITIONS_GET';
export const MAILCHIMP_CONDITIONS_SUCCESS = 'MAILCHIMP_CONDITIONS_SUCCESS';
export const MAILCHIMP_COMMAND_ADD = 'MAILCHIMP_COMMAND_ADD';
export const MAILCHIMP_COMMAND_ADD_SUCCESS = 'MAILCHIMP_COMMAND_ADD_SUCCESS';
export const MAILCHIMP_COMMAND_DELETE = 'MAILCHIMP_COMMAND_DELETE';
export const MAILCHIMP_COMMAND_DELETE_SUCCESS = 'MAILCHIMP_COMMAND_DELETE_SUCCESS';
export const MAILCHIMP_PROCESS = 'MAILCHIMP_PROCESS';
export const MAILCHIMP_PROCESS_SUCCESS = 'MAILCHIMP_PROCESS_SUCCESS';
export const MAILCHIMP_PROCESS_ERROR_GET = 'MAILCHIMP_PROCESS_ERROR_GET';
export const MAILCHIMP_PROCESS_ERROR_GET_SUCCESS = 'MAILCHIMP_PROCESS_ERROR_GET_SUCCESS';
export const MAILCHIMP_PROCESS_FAIL = 'MAILCHIMP_PROCESS_FAIL';

/* ******************** Companies Management ******************************* */

export const COMPANIES_GET_LIST = 'COMPANIES_GET_LIST';
export const COMPANIES_GET_LIST_SUCCESS = 'COMPANIES_GET_LIST_SUCCESS';
export const COMPANIES_SEARCH_START = 'COMPANIES_SEARCH_START';
export const COMPANIES_SEARCH_FINISHED = 'COMPANIES_SEARCH_FINISHED';

export const COMPANIES_GET_LIST_FAIL = 'COMPANIES_GET_LIST_FAIL';
export const COMPANIES_ERROR_REMOVE = 'COMPANIES_ERROR_REMOVE';

// inventory

export const INVENTORY_LIST_FETCH = 'INVENTORY_LIST_FETCH';
export const INVENTORY_LIST_IS_FETCHED = 'INVENTORY_LIST_IS_FETCHED';
export const INVENTORY_LIST_FAIL = 'INVENTORY_LIST_FAIL';

export const INVENTORY_UPLOADING_START = 'INVENTORY_UPLOADING_START';
export const INVENTORY_UPLOADING_FINISH = 'INVENTORY_UPLOADING_FINISH';
export const INVENTORY_UPLOADING_PROGRESS_SET = 'INVENTORY_UPLOADING_PROGRESS_SET';
export const INVENTORY_UPLOADING_PROGRESS_RESET = 'INVENTORY_UPLOADING_PROGRESS_RESET';
export const INVENTORY_UPLOADING_TERMINATE = 'INVENTORY_UPLOADING_TERMINATE';
export const INVENTORY_ERROR_OCCURRED = 'INVENTORY_ERROR_OCCURRED';
export const INVENTORY_ERROR_REMOVE = 'INVENTORY_ERROR_REMOVE';

// dev news
export const NEWS_LIST_FETCH = 'NEWS_LIST_FETCH';
export const NEWS_LIST_IS_FETCHED = 'NEWS_LIST_IS_FETCHED';
export const NEWS_UPLOADING_START = 'NEWS_UPLOADING_START';
export const NEWS_UPLOADING_FINISH = 'NEWS_UPLOADING_FINISH';
export const NEWS_DELETING_START = 'NEWS_DELETING_START';
export const NEWS_DELETING_FINISH = 'NEWS_DELETING_FINISH';
export const NEWS_REORDER = 'NEWS_REORDER';
export const NEWS_REORDER_SUCCESS = 'NEWS_REORDER_SUCCESS';

export const FEATURED_IN_LIST_FETCH = 'FEATURED_IN_LIST_FETCH';
export const FEATURED_IN_LIST_IS_FETCHED = 'FEATURED_IN_LIST_IS_FETCHED';
export const FEATURED_IN_UPLOADING_START = 'FEATURED_IN_UPLOADING_START';
export const FEATURED_IN_UPLOADING_FINISH = 'FEATURED_IN_UPLOADING_FINISH';
export const FEATURED_IN_DELETING_START = 'FEATURED_IN_DELETING_START';
export const FEATURED_IN_DELETING_FINISH = 'FEATURED_IN_DELETING_FINISH';
export const FEATURED_IN_REORDER = 'FEATURED_IN_REORDER';
export const FEATURED_IN_REORDER_SUCCESS = 'FEATURED_IN_REORDER_SUCCESS';

export const NEWS_ERROR = 'NEWS_ERROR';

// emails
export const EMAIL_TEMPLATES_FETCH = 'EMAIL_TEMPLATES_FETCH';
export const EMAIL_TEMPLATES_IS_FETCHED = 'EMAIL_TEMPLATES_IS_FETCHED';
export const ERROR = 'ERROR';

export const USER_REPOSITORY_STATS_GET = 'USER_REPOSITORY_GET';
export const USER_REPOSITORY_STATS_GET_SUCCESS = 'USER_REPOSITORY_GET_SUCCESS';
export const USER_REPOSITORY_STATS_GET_FAIL = 'USER_REPOSITORY_GET_FAIL';
export const USER_REPOSITORY_UNPUBLISH = 'USER_REPOSITORY_UNPUBLISH';
export const USER_REPOSITORY_UNPUBLISH_SUCCESS = 'USER_REPOSITORY_UNPUBLISH_SUCCESS';
export const USER_REPOSITORY_UNPUBLISH_FAIL = 'USER_REPOSITORY_UNPUBLISH_FAIL';
export const USER_REPOSITORY_ERROR_REMOVE = 'USER_REPOSITORY_ERROR_REMOVE';
export const USER_REPOSITORY_DAILY_STATUS_GET = 'USER_DAILY_STATUS_GET';
export const USER_REPOSITORY_DAILY_STATUS_FAIL = 'USER_DAILY_STATUS_FAIL';
export const USER_REPOSITORY_DAILY_STATUS_SUCCESS = 'USER_DAILY_STATUS_SUCCESS';
export const DELETED_STATUS_ID = 3;

// PN list

export const PN_LIST_GET = 'USER_REPOSITORY_GET';
export const PN_LIST_GET_SUCCESS = 'PN_LIST_GET_SUCCESS';
export const PN_LIST_GET_FAIL = 'PN_LIST_GET_FAIL';
export const PN_LIST_UPLOADING_START = 'PN_LIST_UPLOADING_START';
export const PN_LIST_UPLOADING_FINISH = 'PN_LIST_UPLOADING_FINISH';
export const PN_LIST_UPLOADING_PROGRESS_SET = 'PN_LIST_UPLOADING_PROGRESS_SET';
export const PN_LIST_UPLOADING_PROGRESS_RESET = 'PN_LIST_UPLOADING_PROGRESS_RESET';
export const PN_LIST_UPLOADING_TERMINATE = 'PN_LIST_UPLOADING_TERMINATE';
export const PN_LIST_UPLOADING_ERROR_OCCURRED = 'PN_LIST_UPLOADING_ERROR_OCCURRED';
export const PN_LIST_ERROR_REMOVE = 'PN_LIST_ERROR_REMOVE';

// Demand List

export const DEMAND_LIST_UPLOADING_START = 'DEMAND_LIST_UPLOADING_START';
export const DEMAND_LIST_UPLOADING_FINISH = 'DEMAND_LIST_UPLOADING_FINISH';
export const DEMAND_LIST_UPLOADING_PROGRESS_SET = 'DEMAND_LIST_UPLOADING_PROGRESS_SET';
export const DEMAND_LIST_UPLOADING_PROGRESS_RESET = 'DEMAND_LIST_UPLOADING_PROGRESS_RESET';
export const DEMAND_LIST_UPLOADING_TERMINATE = 'DEMAND_LIST_UPLOADING_TERMINATE';
export const DEMAND_LIST_UPLOADING_ERROR_OCCURRED = 'DEMAND_LIST_UPLOADING_ERROR_OCCURRED';
export const DEMAND_LIST_ERROR_REMOVE = 'DEMAND_LIST_ERROR_REMOVE';

// SFTP User

export const SFTP_USER_GET = 'SFTP_USER_GET';
export const SFTP_USER_GET_SUCCESS = 'SFTP_USER_GET_SUCCESS';
export const SFTP_USER_GET_FAIL = 'SFTP_USER_GET_FAIL';
export const SFTP_USER_SAVE = 'SFTP_USER_SAVE';
export const SFTP_USER_SAVE_SUCCESS = 'SFTP_USER_SAVE_SUCCESS';
export const SFTP_USER_SAVE_FAIL = 'SFTP_USER_SAVE_FAIL';
export const SFTP_USER_ERROR_REMOVE = 'SFTP_USER_ERROR_REMOVE';

// Company Weights Management

export const WEIGHT_LIST_CLONE = 'WEIGHT_LIST_CLONE';
export const WEIGHT_LIST_CLONE_SUCCESS = 'WEIGHT_LIST_CLONE_SUCCESS';
export const WEIGHT_LIST_CLONE_FAIL = 'WEIGHT_LIST_CLONE_FAIL';

export const WEIGHT_LIST_UPLOADING_START = 'WEIGHT_LIST_UPLOADING_START';
export const WEIGHT_LIST_UPLOADING_FINISH = 'WEIGHT_LIST_UPLOADING_FINISH';
export const WEIGHT_LIST_UPLOADING_PROGRESS_SET = 'WEIGHT_LIST_UPLOADING_PROGRESS_SET';
export const WEIGHT_LIST_UPLOADING_PROGRESS_RESET = 'WEIGHT_LIST_UPLOADING_PROGRESS_RESET';
export const WEIGHT_LIST_UPLOADING_TERMINATE = 'WEIGHT_LIST_UPLOADING_TERMINATE';
export const WEIGHT_LIST_UPLOADING_ERROR_OCCURRED = 'WEIGHT_LIST_UPLOADING_ERROR_OCCURRED';
export const WEIGHT_LIST_ERROR_REMOVE = 'WEIGHT_LIST_ERROR_REMOVE';

export const USER_CUSTOM_WEIGHTS_GET = 'USER_CUSTOM_WEIGHTS_GET';
export const USER_CUSTOM_WEIGHTS_GET_SUCCESS = 'USER_CUSTOM_WEIGHTS_GET_SUCCESS';
export const USER_CUSTOM_WEIGHTS_SAVE = 'USER_CUSTOM_WEIGHTS_SAVE';
export const USER_CUSTOM_WEIGHTS_SAVE_SUCCESS = 'USER_CUSTOM_WEIGHTS_SAVE_SUCCESS';
export const USER_ATTACHED_LISTS_GET = 'USER_ATTACHED_LISTS_GET';
export const USER_ATTACHED_LISTS_GET_SUCCESS = 'USER_ATTACHED_LISTS_GET_SUCCESS';
export const USER_CUSTOM_WEIGHTS_FAIL = 'USER_CUSTOM_WEIGHTS_FAIL';

// Payment Providers

export const USER_PAYMENT_PROVIDERS_GET = 'USER_PAYMENT_PROVIDERS_GET';
export const USER_PAYMENT_PROVIDERS_GET_SUCCESS = 'USER_PAYMENT_PROVIDERS_GET_SUCCESS';
export const USER_PAYMENT_PROVIDERS_SAVE = 'USER_PAYMENT_PROVIDERS_SAVE';
export const USER_PAYMENT_PROVIDERS_SAVE_SUCCESS = 'USER_PAYMENT_PROVIDERS_SAVE_SUCCESS';
export const USER_PAYMENT_PROVIDER_DETACH = 'USER_PAYMENT_PROVIDER_DETACH';
export const USER_PAYMENT_PROVIDER_DETACH_SUCCESS = 'USER_PAYMENT_PROVIDER_DETACH_SUCCESS';
export const USER_PAYMENT_PROVIDER_COMMISSION_REMOVE = 'USER_PAYMENT_PROVIDER_COMMISSION_REMOVE';
export const USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS = 'USER_PAYMENT_PROVIDER_COMMISSION_REMOVE_SUCCESS';
export const USER_PAYMENT_PROVIDER_METHODS_SAVE = 'USER_PAYMENT_PROVIDER_METHODS_SAVE';
export const USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS = 'USER_PAYMENT_PROVIDER_METHODS_SAVE_SUCCESS';
export const USER_PAYMENT_PROVIDERS_FAIL = 'USER_PAYMENT_PROVIDERS_FAIL';

// Custom Sellers Lists

export const BLOCK_APPROVED_SELLERS_GET = 'BLOCK_APPROVED_SELLERS_GET';
export const BLOCK_APPROVED_SELLERS_GET_SUCCESS = 'BLOCK_APPROVED_SELLERS_GET_SUCCESS';
export const CUSTOM_SELLERS_LISTS_GET = 'CUSTOM_SELLERS_LISTS_GET';
export const CUSTOM_SELLERS_LISTS_SUCCESS = 'CUSTOM_SELLERS_LISTS_SUCCESS';
export const CUSTOM_SELLERS_LISTS_SAVE = 'CUSTOM_SELLERS_LISTS_SAVE';
export const CUSTOM_SELLERS_LISTS_SAVE_SUCCESS = 'CUSTOM_SELLERS_LISTS_SAVE_SUCCESS';
export const CUSTOM_SELLERS_LISTS_DELETE = 'CUSTOM_SELLERS_LISTS_DELETE';
export const CUSTOM_SELLERS_LISTS_DELETE_SUCCESS = 'CUSTOM_SELLERS_LISTS_DELETE_SUCCESS';
export const CUSTOM_SELLERS_LISTS_FAIL = 'CUSTOM_SELLERS_LISTS_FAIL';

// Parts Watchlist

export const PARTS_WATCHLIST_GET = 'CUSTOM_SELLERS_LISTS_GET';
export const PARTS_WATCHLIST_GET_SUCCESS = 'PARTS_WATCHLIST_GET_SUCCESS';
export const PARTS_WATCHLIST_ALL_GET = 'PARTS_WATCHLIST_ALL_GET';
export const PARTS_WATCHLIST_ALL_GET_SUCCESS = 'PARTS_WATCHLIST_ALL_GET_SUCCESS';
export const PARTS_WATCHLIST_ADD = 'PARTS_WATCHLIST_SAVE';
export const PARTS_WATCHLIST_ADD_SUCCESS = 'PARTS_WATCHLIST_SAVE_SUCCESS';
export const PARTS_WATCHLIST_DELETE = 'PARTS_WATCHLIST_DELETE';
export const PARTS_WATCHLIST_DELETE_SUCCESS = 'PARTS_WATCHLIST_DELETE_SUCCESS';
export const PARTS_WATCHLIST_FAIL = 'PARTS_WATCHLIST_FAIL';

export const BUYERS_LISTS_GET = 'BUYERS_LISTS_GET';
export const BUYERS_LISTS_SUCCESS = 'BUYERS_LISTS_SUCCESS';
export const BUYERS_LISTS_SAVE = 'BUYERS_LISTS_SAVE';
export const BUYERS_LISTS_SAVE_SUCCESS = 'BUYERS_LISTS_SAVE_SUCCESS';
export const BUYERS_LISTS_DELETE = 'BUYERS_LISTS_DELETE';
export const BUYERS_LISTS_DELETE_SUCCESS = 'BUYERS_LISTS_DELETE_SUCCESS';
export const BUYERS_LISTS_FAIL = 'CUSTOM_SELLERS_LISTS_FAIL';

export const API_PERMISSIONS_GET = 'API_PERMISSIONS_GET';
export const API_PERMISSIONS_GET_SUCCESS = 'API_PERMISSIONS_GET_SUCCESS';
export const API_PERMISSIONS_GET_SAVE = 'API_PERMISSIONS_GET_SAVE';
export const API_PERMISSIONS_SAVE_SUCCESS = 'API_PERMISSIONS_SAVE_SUCCESS';
export const INTEGRATION_GET = 'INTEGRATION_SETTINGS_GET';
export const INTEGRATION_GET_SUCCESS = 'INTEGRATION_SETTINGS_GET_SUCCESS';
export const INTEGRATION_SAVE = 'INTEGRATION_SAVE';
export const INTEGRATION_SAVE_SUCCESS = 'INTEGRATION_SAVE_SUCCESS';
export const GENERATE_START = 'GENERATE_START';
export const GENERATE_SUCCESS = 'GENERATE_START_SUCCESS';
export const REVOKE_START = 'REVOKE_START';
export const REVOKE_SUCCESS = 'REVOKE_START_SUCCESS';
export const SPEC2K_FAIL = 'SPEC2K_FAIL';
export const CLEAR_SPEC2K = 'CLEAR_SPEC2K';
export const SUPPLIER_CODES_GET = 'SUPPLIER_CODES_GET';
export const SUPPLIER_CODES_GET_SUCCESS = 'SUPPLIER_CODES_GET_SUCCESS';
export const SUPPLIER_CODE_ADD = 'SUPPLIER_CODES_ADD';
export const SUPPLIER_CODE_ADD_SUCCESS = 'SUPPLIER_CODES_ADD_SUCCESS';
export const SUPPLIER_CODE_EDIT = 'SUPPLIER_CODE_SAVE';
export const SUPPLIER_CODE_EDIT_SUCCESS = 'SUPPLIER_CODE_SAVE_SUCCESS';
export const SUPPLIER_CODE_DELETE = 'SUPPLIER_CODE_DELETE';
export const SUPPLIER_CODE_DELETE_SUCCESS = 'SUPPLIER_CODE_DELETE_SUCCESS';

export const USER_ORDER_LIMITS_GET = 'USER_ORDER_LIMITS_GET';
export const USER_ORDER_LIMITS_GET_SUCCESS = 'USER_ORDER_LIMITS_GET_SUCCESS';
export const USER_ORDER_LIMITS_SAVE = 'USER_ORDER_LIMITS_SAVE';
export const USER_ORDER_LIMITS_SAVE_SUCCESS = 'USER_ORDER_LIMITS_SAVE_SUCCESS';
export const USER_ORDER_LIMITS_FAIL = 'USER_ORDER_LIMITS_FAIL';
export const USER_ORDER_LIMITS_RESET = 'USER_ORDER_LIMITS_RESET';

export const PRO_MANAGEMENT_GET = 'PRO_MANAGEMENT_GET';
export const PRO_MANAGEMENT_GET_SUCCESS = 'PRO_MANAGEMENT_GET_SUCCESS';
export const PRO_MANAGEMENT_CANCEL_SUB = 'PRO_MANAGEMENT_CANCEL_SUB';
export const PRO_MANAGEMENT_CANCEL_SUB_SUCCESS = 'PRO_MANAGEMENT_CANCEL_SUB_SUCCESS';
export const PRO_MANAGEMENT_STOP_SUB = 'PRO_MANAGEMENT_STOP_SUB';
export const PRO_MANAGEMENT_STOP_SUB_SUCCESS = 'PRO_MANAGEMENT_STOP_SUB_SUCCESS';
export const PRO_MANAGEMENT_CONTINUE_SUB = 'PRO_MANAGEMENT_CONTINUE_SUB';
export const PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS = 'PRO_MANAGEMENT_CONTINUE_SUB_SUCCESS';
export const PRO_MANAGEMENT_MANUAL_SUB = 'PRO_MANAGEMENT_MANUAL_SUB';
export const PRO_MANAGEMENT_MANUAL_SUB_SUCCESS = 'PRO_MANAGEMENT_MANUAL_SUB_SUCCESS';
export const PRO_MANAGEMENT_FAIL = 'PRO_MANAGEMENT_FAIL';
export const PRO_MANAGEMENT_CLEAR = 'PRO_MANAGEMENT_CLEAR';
export const PRO_MANAGEMENT_HISTORY_GET = 'PRO_MANAGEMENT_HISTORY_GET';
export const PRO_MANAGEMENT_HISTORY_GET_SUCCESS = 'PRO_MANAGEMENT_HISTORY_GET_SUCCESS';

/* ******************** Network Operations Center ******************************* */

export const BUILTIN_QUOTE_SEND = 'BUILTIN_QUOTE_SEND';
export const BUILTIN_QUOTE_SEND_SUCCESS = 'BUILTIN_QUOTE_SEND_SUCCESS';

export const BUILTIN_NOQUOTE_SEND = 'BUILTIN_NOQUOTE_SEND';
export const BUILTIN_NOQUOTE_SEND_SUCCESS = 'BUILTIN_NOQUOTE_SEND_SUCCESS';

export const BUILTIN_VALIDATE = 'BUILTIN_VALIDATE';
export const BUILTIN_VALIDATE_SUCCESS = 'BUILTIN_VALIDATE_SUCCESS';

export const BUILTIN_QUOTE_EDIT = 'BUILTIN_QUOTE_EDIT';
export const BUILTIN_QUOTE_EDIT_SUCCESS = 'BUILTIN_QUOTE_EDIT_SUCCESS';

export const TAG_FILE_REMOVE = 'TAG_FILE_REMOVE';
export const TAG_FILE_REMOVE_SUCCESS = 'TAG_FILE_REMOVE_SUCCESS';

export const BUILTIN_QUOTE_REMOVE = 'BUILTIN_QUOTE_REMOVE';
export const BUILTIN_QUOTE_REMOVE_SUCCESS = 'BUILTIN_QUOTE_REMOVE_SUCCESS';

export const TASKS_OPERATION_FAIL = 'TASKS_OPERATION_FAIL';
export const BUILTIN_QUOTE_SCROLLED = 'BUILTIN_QUOTE_SCROLLED';

export const BUILTIN_QUOTE_MARK_PO = 'BUILTIN_QUOTE_MARK_PO';
export const BUILTIN_QUOTE_MARK_PO_SUCCESS = 'BUILTIN_QUOTE_MARK_PO_SUCCESS';

export const TASKS_LIST_GET = 'TASKS_LIST_GET';
export const TASKS_LIST_GET_SUCCESS = 'TASKS_LIST_GET_SUCCESS';
export const TASK_START = 'TASK_START';
export const TASK_START_SUCCESS = 'TASK_START_SUCCESS';
export const TASK_CLOSE = 'TASK_CLOSE';
export const TASK_CLOSE_SUCCESS = 'TASK_CLOSE_SUCCESS';
export const TASK_STEP_FORWARD = 'TASK_STEP_FORWARD';
export const TASK_STEP_FORWARD_SUCCESS = 'TASK_STEP_FORWARD_SUCCESS';
export const TASK_REOPEN = 'TASK_REOPEN';
export const TASK_REOPEN_SUCCESS = 'TASK_REOPEN_SUCCESS';
export const TASK_DATA_FETCH = 'TASK_DATA_FETCH';
export const TASK_DATA_FETCH_SUCCESS = 'TASK_DATA_FETCH_SUCCESS';
export const NOC_SECTION_FAIL = 'NOC_SECTION_FAIL';
export const TASK_SET_VIEWED = 'TASK_SET_VIEWED';
export const SET_TASK_FETCHING_PARAMS = 'SET_TASK_FETCHING_PARAMS';

export const MESSAGE_SEND = 'MESSAGE_SEND';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const WIRE_TRANSFER_CONFIRM = 'WIRE_TRANSFER_CONFIRM';
export const WIRE_TRANSFER_CONFIRM_SUCCESS = 'WIRE_TRANSFER_CONFIRM_SUCCESS';
export const EPLANE_PAYMENT_REPORT = 'PAYMENT_REPORT';
export const EPLANE_PAYMENT_REPORT_SUCCESS = 'PAYMENT_REPORT_SUCCESS';
export const PAYMENT_REQUEST_ADD = 'PAYMENT_REQUEST_ADD';
export const PAYMENT_REQUEST_ADD_SUCCESS = 'PAYMENT_REQUEST_ADD_SUCCESS';
export const NOC_PAYMENTS_FAIL = 'NOC_PAYMENTS_FAIL';
export const DOC_UPLOADING_PAYMENTS_PROGRESS_SET = 'DOC_UPLOADING_PAYMENTS_PROGRESS_SET';
export const DOC_UPLOADING_PAYMENTS_START = 'DOC_UPLOADING_PAYMENTS_START';
export const DOC_UPLOADING_PAYMENTS_FINISH = 'DOC_UPLOADING_PAYMENTS_FINISH';
export const DOC_UPLOADING_PAYMENTS_TERMINATE = 'DOC_UPLOADING_PAYMENTS_TERMINATE';
export const DOC_UPLOADING_PAYMENTS_REMOVE = 'DOC_UPLOADING_PAYMENTS_REMOVE';
export const SET_SHOWN_FORM = 'SET_SHOWN_FORM';
export const HIDE_SHOWN_FORM = 'HIDE_SHOWN_FORM';
export const SET_TASKS_BAR = 'SET_TASKS_BAR';
export const SET_HISTORY_BAR = 'SET_HISTORY_BAR';

export const TASK_IGNORE_LIST_GET = 'TASK_IGNORE_LIST_GET';
export const TASK_IGNORE_LIST_GET_SUCCESS = 'TASK_IGNORE_LIST_GET_SUCCESS';
export const TASK_IGNORE_LIST_SAVE = 'TASK_IGNORE_LIST_SAVE';
export const TASK_IGNORE_LIST_SAVE_SUCCESS = 'TASK_IGNORE_LIST_SAVE_SUCCESS';
export const TASK_IGNORE_LIST_DELETE = 'TASK_IGNORE_LIST_DELETE';
export const TASK_IGNORE_LIST_DELETE_SUCCESS = 'TASK_IGNORE_LIST_DELETE_SUCCESS';
export const TASK_IGNORE_LIST_FAIL = 'TASK_IGNORE_LIST_FAIL';

/* ******************** Settings Page ******************************* */

export const WEIGHT_GROUPS_GET = 'WEIGHT_GROUPS_GET';
export const WEIGHT_GROUPS_GET_SUCCESS = 'WEIGHT_GROUPS_GET_SUCCESS';
export const WEIGHT_GROUPS_ERROR_REMOVE = 'WEIGHT_GROUPS_ERROR_REMOVE';

export const WEIGHT_GROUP_LIST_GET = 'WEIGHT_GROUP_LIST_GET';
export const WEIGHT_GROUP_LIST_GET_SUCCESS = 'WEIGHT_GROUP_LIST_GET_SUCCESS';

export const WEIGHT_GROUP_LIST_SAVE = 'WEIGHT_GROUP_LIST_SAVE';
export const WEIGHT_GROUP_LIST_SAVE_SUCCESS = 'WEIGHT_GROUP_LIST_SAVE_SUCCESS';
export const WEIGHT_GROUP_LIST_UPDATE_SUCCESS = 'WEIGHT_GROUP_LIST_UPDATE_SUCCESS';

export const WEIGHT_GROUP_REMOVE = 'WEIGHT_GROUP_REMOVE';
export const WEIGHT_GROUP_REMOVE_SUCCESS = 'WEIGHT_GROUP_REMOVE_SUCCESS';

export const WEIGHT_GROUP_ATTACH_DETACH = 'WEIGHT_GROUP_ATTACH_DETACH';
export const WEIGHT_GROUP_ATTACH_SUCCESS = 'WEIGHT_GROUP_ATTACH_SUCCESS';
export const WEIGHT_GROUP_DETACH_SUCCESS = 'WEIGHT_GROUP_DETACH_SUCCESS';

export const VENDOR_CATEGORIES_GET = 'VENDOR_CATEGORIES_GET';
export const VENDOR_CATEGORIES_GET_SUCCESS = 'VENDOR_CATEGORIES_GET_SUCCESS';
export const VENDOR_CATEGORIES_SAVE = 'VENDOR_CATEGORIES_SAVE';
export const VENDOR_CATEGORIES_SAVE_SUCCESS = 'VENDOR_CATEGORIES_SAVE_SUCCESS';
export const VENDOR_CATEGORIES_FAIL = 'VENDOR_CATEGORIES_SAVE_FAIL';

export const MISC_SETTINGS_EDIT_MODE_TOGGLE = 'MISC_SETTINGS_EDIT_MODE_TOGGLE';
export const RFQ_EMAIL_SETTINGS_GET = 'RFQ_EMAIL_SETTINGS_GET';
export const RFQ_EMAIL_SETTINGS_SUCCESS = 'RFQ_EMAIL_SETTINGS_SUCCESS';
export const RFQ_EMAIL_SETTINGS_SAVE = 'RFQ_EMAIL_SETTINGS_SAVE';
export const RFQ_EMAIL_SETTINGS_SAVE_SUCCESS = 'RFQ_EMAIL_SETTINGS_SAVE_SUCCESS';
export const DIGESTED_EMAILS_GET = 'DIGESTED_EMAILS_GET';

export const EMAILS_FAIL = 'EMAILS_ERROR_REMOVE';

export const PAYMENT_PROVIDERS_GET = 'PAYMENT_PROVIDERS_GET';
export const PAYMENT_PROVIDERS_GET_SUCCESS = 'PAYMENT_PROVIDERS_GET_SUCCESS';
export const PAYMENT_PROVIDERS_SAVE = 'PAYMENT_PROVIDERS_SAVE';
export const PAYMENT_PROVIDERS_SAVE_SUCCESS = 'PAYMENT_PROVIDERS_SAVE_SUCCESS';
export const PAYMENT_PROVIDERS_FAIL = 'PAYMENT_PROVIDERS_FAIL';

export const ORDER_LIMITS_GET = 'MOV_GET';
export const ORDER_LIMITS_GET_SUCCESS = 'MOV_GET_SUCCESS';
export const ORDER_LIMITS_SAVE = 'MOV_SAVE';
export const ORDER_LIMITS_SAVE_SUCCESS = 'MOV_SAVE_SUCCESS';
export const ORDER_LIMITS_FAIL = 'MOV_FAIL';
export const ORDER_LIMITS_RESET = 'ORDER_LIMITS_RESET';

export const BANK_ACCOUNT_GET = 'BANK_ACCOUNT_GET';
export const BANK_ACCOUNT_GET_SUCCESS = 'BANK_ACCOUNT_GET_SUCCESS';
export const BANK_ACCOUNT_SAVE = 'BANK_ACCOUNT_SAVE';
export const BANK_ACCOUNT_SAVE_SUCCESS = 'BANK_ACCOUNT_SAVE_SUCCESS';
export const BANK_ACCOUNT_FAIL = 'BANK_ACCOUNT_FAIL';
export const BANK_ACCOUNT_RESET = 'BANK_ACCOUNT_RESET';

export const PRO_PLAN_PRICE_GET = 'PRO_PLAN_PRICE_GET';
export const PRO_PLAN_PRICE_GET_SUCCESS = 'PRO_PLAN_PRICE_GET_SUCCESS';
export const PRO_PLAN_PRICE_SAVE = 'PRO_PLAN_PRICE_SAVE';
export const PRO_PLAN_PRICE_SAVE_SUCCESS = 'PRO_PLAN_PRICE_SAVE_SUCCESS';
export const PRO_PLAN_PRICE_FAIL = 'PRO_PLAN_PRICE_FAIL';
export const PRO_PLAN_PRICE_RESET = 'PRO_PLAN_PRICE_RESET';

/* ******************** Logging ******************************* */
export const LOGS_DATA_FETCH = 'LOGS_DATA_FETCH';
export const LOGS_DATA_FETCH_SUCCESS = 'LOGS_DATA_FETCH_SUCCESS';
export const LOGS_DATA_FETCH_FAIL = 'LOGS_DATA_FETCH_FAIL';

export const BOUNCE_EMAILS_GET = 'BOUNCE_EMAILS_GET';
export const BOUNCE_EMAILS_GET_SUCCESS = 'BOUNCE_EMAILS_GET_SUCCESS';
export const BOUNCE_EMAILS_FAIL = 'BOUNCE_EMAILS_FAIL';

/* ******************** Errors Management ******************************* */

export const ERRORS_ADD = 'ERRORS_ADD';
export const ERRORS_RESET = 'ERRORS_RESET';

/* ******************** Toasts Management ******************************* */

export const TOAST_ADD = 'TOAST_ADD';
export const TOAST_DELETE = 'TOAST_DELETE';

/* ******************** Chat ******************************* */
export const CHAT_COMMENT_ADD = 'CHAT_COMMENT_ADD';
export const CHAT_COMMENT_ADD_SUCCESS = 'CHAT_COMMENT_ADD_SUCCESS';
export const CHAT_UPDATE_REQUIRED = 'CHAT_UPDATE_REQUIRED';
export const CHAT_FAIL = 'CHAT_FAIL';

/* ******************** Uploader ******************************* */
export const UPLOADING_START = 'UPLOADING_START';
export const UPLOADING_FINISH = 'UPLOADING_FINISH';
export const UPLOADING_PROGRESS_SET = 'UPLOADING_PROGRESS_SET';
export const UPLOADING_PROGRESS_RESET = 'UPLOADING_PROGRESS_RESET';
export const UPLOADING_TERMINATE = 'UPLOADING_TERMINATE';
export const UPLOADING_ERROR_OCCURRED = 'UPLOADING_ERROR_OCCURRED';
export const UPLOADING_RESET = 'UPLOADING_ERROR_REMOVE';

// Filter's statuses when notifications are not sent:
export const filterStatusExceptions = {
  sales: ['act_rfq_seller_responded', 'act_rfq_no_quote', 'inactive_rfq'],
  purchases: ['act_rfq_buyer_responded', 'pending_rfq', 'inactive_rfq'],
};

export const font = {
  normal: '\'Lato\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
  bold: '\'Lato Black\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
};

export const formOptions = {
  labelColumnWidth: 4,
  controlColumnWidth: 8,
  defaultInputLength: 255,
};

export const color = {
  altoGrey: '#e0e0e0',
  backgroundGrey: '#fafafa',
  borderLightGrey: '#c1c1c1',
  silverGrey: '#ababab',
  stripedGrey: '#f1f1f1',
  grey: '#808080',
  lightGrey: '#eee',
  darkGrey: '#333',
  whiteSmoke: '#F5F5F5',
  whiteRose: '#feece6',
  blue: '#4854bb',
  darkBlue: '#283261',

  googleBlue: '#4285f4',

  red: 'red',

  inputBorder: '#66afe9',

  white: '#fff',

  birch: '#6575ff',
  oracle: '#283261',
  lightBirch: '#E5F1F4',
  lightCyan: '#deebef',
  darkBirch: '#283261',
  textGreen: '#00AB44',
  iconGreen: '#6bd497',
  magicMint: '#a7ffd0',
  textRed: '#BF0000',
  tomatoRed: '#ff6250',
  goldenTainoi: '#F5B24B',
  darkorange: 'darkorange',
};

export const BASE_DECLINE_COMMENT = 'We had difficulties confirming the following: ';

export const orderActionTypeMap = {
  approve: 'Approved',
  approve_new_total: 'Approved',
  cancel: 'Canceled',
  comment: 'Commented',
  create: 'Created',
  decline: 'Declined',
  decline_automatically: 'Declined',
  delete: 'Deleted',
  expire: 'Expired',
  expire_soon: 'Expired',
  pay: 'Paid',
  place: 'Placed',
  update: 'Updated',
  withdraw: 'Withdrew',
};

// default data for select tag
export const DEFAULT_SELECT_KEY = '-99999999';
export const DEFAULT_USER_SETTINGS = '-1';
export const DEFAULT_SELECT_VALUE = 'select';

export const DEFAULT_COUNTRY_VALUE_SELECT = 'Please select the country';
export const DEFAULT_REGION_VALUE_SELECT = 'Please select the state/province';

export const OTHER_SELECT_PICKUP_KEY = '-99999999';

// Date & Time constants
export const millisecondsPerHour = 3600000;
export const millisecondsPerMinute = 60000;

export const NUM_OF_ORDERS_PER_PAGE = 100;
export const DEFAULT_TASK_AMOUNT_PER_PAGE = 20;

export const INITIAL_COMMENTS_SIZE = 100;

export const MAX_PARTS_WATCHLIST_ITEMS = 4000;

export const ERRORS = {
  ERR_API_BASE: 1,
  ERR_PARSE_INPUT: 2,
  ERR_RENDER_OUTPUT: 3,
  ERR_INVALID_INPUT: 4,
  ERR_INVALID_KEY: 5,
  ERR_INVALID_VALUE: 6,
  ERR_REQUIRED_FIELD: 7,
  ERR_REQUIRED_VALUE: 8,
  ERR_DENIED: 9,
  ERR_ENTITY_NOT_FOUND: 10,
  ERR_ENTITY_ALREADY_EXIST: 11,
  ERR_ALREADY_DONE: 12,
  ERR_LIMIT_EXCEEDED: 13,
  ERR_NETWORK_ERROR: 14,
  ERR_PERMISSION_DENIED: 104,

  SUCCESS: 1000,

  // file errors
  ERR_WRONG_FORMAT: 10001,
  ERR_FILE_IS_TOO_LARGE: 10002,
  ERR_SECOND_FILE_IS_TOO_LARGE: 10003,
  ERR_FILE_CONTENT_NOT_SUPPORTED: 1701,
};

export const TOASTS_MAX_COUNT = 5;
export const TOASTS_DEFAULT_DELAY = 7000;

export const AXIOS_CANCELLED_MESSAGE = 'REQUEST_IS_CANCELLED';

export const BOT_SERVICE_MSG = '-1';

export const fileFormatsUM = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const fileFormatsInventoryIM = [
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-office',
  'application/wps-office.xlsx',
  'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
];

export const fileFormatsArchiveIM = [
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
];

export const fileLogoFormatsUM = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
];

export const fileFormatsAny = [
  'text/plain',
  'text/csv',
  'text/rtf',

  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',

  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-office',
  'application/vnd.ms-powerpoint',

  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  'image/x-ms-bmp',

  'application/zip',
  'application/x-rar',
  'application/x-tar',
  'application/x-gzip',
  'application/x-bzip2',
  'application/x-7z-compressed',

  'application/octet-stream',

  'video/mp4',
  'video/x-ms-asf',
  'video/x-msvideo',
  'video/mpeg',
  'audio/mpeg',
  'video/quicktime',
  'video/x-flv',
];

export const notificationTypes = {
  TASKS: 'tasks',
  GENERAL: 'general',
};

// max file sizes in bytes
export const maxFileSizeUM = 250 * 1024 * 1024; // 250 Mb
export const maxFileSizeIM = 250 * 1024 * 1024; // 250 Mb
export const maxFileSizeSendMsg = 10 * 1024 * 1024; // 10 Mb
export const maxFileSizeDemand = 5 * 1024 * 1024; // 5 Mb
export const maxFileSizeImArc = 1024 * 1024 * 1024; // 1 Gb
export const maxLogoFileSizeUM = 5 * 1024 * 1024; // 5Mb;
export const maxFileSizePWL = 5 * 1024 * 1024; // 5Mb;
export const maxFileSizeWeightList = 1 * 1024 * 1024; // 1 Mb

export const MAIL_READER_KEY = 'ssh-rsa '
+ 'AAAAB3NzaC1yc2EAAAADAQABAAABAQC8yjJ9MMuIjobA99bTTH1jnHyRtUm89a1p8SM7wWo0rZp7Cdc3EdCy3aPZEJ+qRG7csqvEzBjqPGnkMq2qTu1SLo6Z5lITMU8GGLH3dS9'
+ 'hGylE6CZfcIK2aO4nwdJNfQ5P7ugpuqjfqxJvBXFRC4OWkqINtdYskz4s9VzElC/Gh/ppaPT86CsOUwAJpP98rJwFdEBpTZBx7/o0D3EWsnBF2EmFf5RLe7x4wAy5s5FOl5uNPM'
+ 'Gx9hVksoV6nOOzB4IBY0rHLx/DJ/y0jH2e8hot7khRfkhe53FzpzxKYL1bM1IylwOR9OQfG6ttBMqvfIRdxCZAqIE5zv8PH8ASlkrp ePlane Mail Reader Utility';

export const MAIL_ROTABULL_KEY = 'ssh-rsa '
+ 'AAAAB3NzaC1yc2EAAAADAQABAAABAQDUzIZxgQEDdoj2sE9U6+9nXMsRJ5gEwUnNJXJHlp4JxKAUD03aPAynhJu9Qlk0tjU/H+rDXuB5vt+R89zy90JNiVGoxxDSdc6djTLCygO7'
+ 'l6seKr/XQ0hxZrPE/fdyWA8mcvvtRTegOejOSUKiRQU1LWxoyZYFsF1ObWtO0kYAUAm1/0qCELdopg5Zcse3Vf1kvvMYSEJzhfoi8jLFrzAa4oIaGmMkaxvVNllJLNfiII4vexJ5'
+ '1wVBMUCfJ1XL/h2A0Wpel6ypTqzJHAK/HKgxMIaZB+37qM7rcC43DBhj595+uy/u6GYBIs4qaeNUSScBgJ88bgcn5ng5GjkvHBMr Rotabull';
