import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

import roles from '../../constants/backofficeRoles';
import NavA from './NavA';
import { color, PUBLIC_URL } from '../../constants';
import getAllowedRoutes from '../../utils/getAllowedRoutes';

const NavItem = styled(NavA)`
  margin: 1em 0;
  color: ${color.blue};
  @media (max-width: 1200px) {
    margin: 0;
  }
`;
const rotesConfig = [
  {
    component: null,
    path: '/masterParts',
    title: 'Master Parts',
    permission: [roles.ADMIN],
  },
  {
    component: null,
    path: '/eplaneSettings',
    title: 'Eplane Settings',
    permission: [roles.ADMIN],
  },
  {
    component: null,
    path: '/paymentsTasks',
    title: 'Payments Tasks',
  },
  {
    component: null,
    path: '/networkOperations',
    title: 'Tasks',
  },
  {
    component: null,
    path: '/incoming',
    title: 'Incoming Requests',
  },
  {
    component: null,
    path: '/orders',
    title: 'Orders',
  },
  {
    component: null,
    path: '/userManagement',
    title: 'Users',
  },
  {
    component: null,
    path: '/companies',
    title: 'Companies',
  },
];

export default () => (
  <>
    {getAllowedRoutes(rotesConfig).map((route) => (
      <NavDropdown.Item
        key={route.path}
        to={`${PUBLIC_URL}${route.path}`}
        as={NavItem}
        bsPrefix="custom"
      >
        {route.title}
      </NavDropdown.Item>
    ))}
  </>
);
